@import "spinner.css";
@import "./contants.scss";

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

a {
  color: #666;
  text-decoration: none;
}

span {
  display: block;
}

/* header */

div.AppHeader {
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media print {
    display: none;
  }
}

/* main page */

div.recentExercisesList {
  ul li {
    list-style-type: none;
  }
}

/* exercise info */

div.settingsNotes {
  display: none;
}

/* summary */

a.returnLink,
div.finishButton {
  @media print {
    display: none;
  }
}

/* comparison */
table {
  margin: 20px auto;
  text-align: center;

  tr {
    height: 40px;
  }

  span {
    margin: 0 !important;
    padding: 0;
  }
}
